<template>
  <v-dialog
    v-if="canAddEntity"
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        x-small
        fab
        icon
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-form>
      <v-card :style="{ 'min-height': '410px', position: 'relative' }">
        <v-card-title>
          <span class="headline">Add new</span>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-tabs
            v-model="tab"
            grow
          >
            <v-tabs-slider />
            <v-tab
              v-for="item in tabs"
              :key="item.name"
            >
              {{ item.name }}
            </v-tab>
          </v-tabs>

          <v-radio-group
            v-model="type"
            row
          >
            <template v-slot:label>
              <h3>Selected type: </h3>
            </template>
            <v-radio
              v-for="(item, i) in types"
              :key="i"
              :label="item.name"
              :value="item.value"
            />
          </v-radio-group>
          <v-tabs-items v-model="tab">
            <v-tab-item
              class="mt-3"
            >
              <v-text-field
                v-model="name"
                label="Enter a name"
                outlined
                :rules="nameRules"
                dense
              />
              <v-text-field
                v-model="description"
                label="Write a short description (Optional)"
                outlined
                dense
              />
            </v-tab-item>
            <v-tab-item
              class="mt-3"
            >
              <v-file-input
                v-model="file"
                accept=".csv"
                label="Upload a CSV file"
                outlined
                dense
                prepend-icon=""
                prepend-inner-icon="$file"
                hint="The file must include a single column with the names only."
                persistent-hint
                @change="fileChanged"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions style="position: absolute; bottom: 0; width: 100%;">
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :disabled="loadingSave"
            @click="close"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loadingSave"
            :disabled="!valid"
            @click="addEntity"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Papa from 'papaparse'

export default {
  props: {
    entityId: {
      type: String,
      required: true
    },
    tenantId: {
      type: String,
      required: true
    },
    username: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      canAddEntity: false,
      types: [
        { name: 'Group', value: 'GROUP' },
        { name: 'Site', value: 'SITE' }
      ],
      type: 'GROUP',
      name: null,
      description: null,
      dialog: false,
      nameRules: [v => !!((v ?? '').trim()) || 'Please fill in this field'],
      loadingSave: false,
      tab: 0,
      tabs: [
        { name: 'Create single' },
        { name: 'Create in bulk' }
      ],
      file: null,
      names: [],
      isParseComplete: false
    }
  },
  computed: {
    valid () {
      if (this.tab === 0) {
        return !!this.name
      }
      return !!this.file && !!this.isParseComplete
    }
  },
  async created () {
    await this.canEditEntity()
  },
  methods: {
    async canEditEntity () {
      try {
        this.canAddEntity = await this.enforce(this.entityId, 'entity-edit', this.username, this.tenantId)
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
      }
    },
    async enforce (entity, action, userName, tenantId) {
      this.loading = true
      const enforce = await this.$store.dispatch('enforce', {
        userName,
        tenantId,
        entity,
        action
      })
      this.loading = false
      return enforce
    },
    clearInputs () {
      this.type = 'GROUP'
      this.name = null
      this.description = null
      this.file = null
      this.tab = 0
      this.isParseComplete = false
      this.names = []
    },
    close () {
      this.dialog = false
      this.clearInputs()
    },
    fileChanged () {
      this.isParseComplete = false
      if (!this.file) {
        return
      }
      Papa.parse(this.file, {
        complete: (results) => {
          for (const el of results.data) {
            if (el[0] === '') {
              continue
            }
            this.names.push(el[0])
          }
          this.isParseComplete = true
        }
      })
    },
    async addSingle () {
      return await this.dispatchAddEntity({
        parentEntityId: this.entityId,
        type: this.type,
        name: this.name,
        description: this.description
      }, `${this.name} was created successfully`, 'add')
    },

    async addBulk () {
      return await this.dispatchAddEntitiesInBulk({
        parentEntityId: this.entityId,
        type: this.type,
        names: this.names
      }, `${this.names.length} ${this.type.toLowerCase()}s were created successfully`, 'add:multi')
    },

    async addEntity () {
      if (!this.valid) {
        return null
      }
      this.loadingSave = true
      try {
        const metadata = (this.tab === 0) ? await this.addSingle() : await this.addBulk()
        this.$emit(metadata.eventName, metadata.data)
        this.$store.commit('showMessage', { message: metadata.message, color: 'success' })
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
      } finally {
        this.loadingSave = false
        this.close()
      }
    },
    async dispatchAddEntity (payload, successMessage, eventName) {
      const { data } = await this.$store.dispatch('addEntity', payload)
      return { data, message: successMessage, eventName }
    },
    async dispatchAddEntitiesInBulk (payload, successMessage, eventName) {
      const { data } = await this.$store.dispatch('addEntitiesInBulk', payload)
      return { data: data.entities, message: successMessage, eventName }
    }
  }
}
</script>

<style scoped>
.v-tab {
  text-transform: none !important;
}
</style>
