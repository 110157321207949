<template>
  <v-card>
    <div>
      <v-card-title
        class="primary white--text headline"
      >
        <v-text-field
          v-model="search"
          class="ml-5"
          :label="$t('Search Sites Directory')"
          prepend-inner-icon="mdi-feature-search-outline"
          dark
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        />
      </v-card-title>
      <div v-if="loading">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto"
        />
      </div>
      <div
        v-else-if="!loading && !entities.length"
        class="ml-2"
      >
        <br>Welcome new user!
        <br>Roles are not yet mapped for you.
      </div>
      <v-row
        v-else
        class="pa-4"
        justify="space-between"
      >
        <v-col cols="3">
          <v-treeview
            :active.sync="active"
            :items="entities"
            :open.sync="open"
            :search="search"
            :filter="filter"
            item-text="nameAndNumber"
            open-all
            item-children="child_entities"
            item-key="entity.id"
            activatable
            loading-icon
            color="warning"
            transition
            return-object
          >
            <template v-slot:append="{ item }">
              <add-entity-dialog
                v-if="item.entity.entity_type !== 'SITE'"
                :entity-id="item.entity.id"
                :tenant-id="currentTenantId"
                :username="userName"
                @add="onEntityAdd(item, $event)"
                @add:multi="onEntitiesAdd(item, $event)"
              />
            </template>
            <template v-slot:prepend="{ item }">
              <div v-if="item.entity.entity_type === 'TENANT'">
                <v-icon>
                  mdi-store
                </v-icon>
              </div>
              <div v-else>
                <v-icon v-if="item.entity.entity_type === 'SITE'">
                  mdi-gas-station
                </v-icon>
                <v-icon v-else>
                  mdi-sitemap
                </v-icon>
              </div>
            </template>
          </v-treeview>
        </v-col>

        <v-divider
          vertical
          class="ma-0 pa-0"
        />

        <v-col cols="8">
          <v-scroll-y-transition mode="out-in">
            <div
              v-if="!selected"
              class="title grey--text text--lighten-1 font-weight-light"
              style="align-self: center;"
            >
              {{ $t("Select a Group or a Site") }}
            </div>
            <v-card
              v-else
              :key="selected.entity.id"
              class="mx-auto"
              max-width="1200"
              flat
            >
              <v-card-text>
                <info-card-tenant
                  v-if="selected.entity.entity_type === 'TENANT'"
                  :id="selected.entity.id"
                  :name="selected.entity.name"
                  :tenant-id="currentTenantId"
                  :user-name="userName"
                  @delete="entityDeleted"
                  @change:selected="changeSelected($event)"
                />
                <info-card-group
                  v-if="selected.entity.entity_type === 'GROUP'"
                  :id="selected.entity.id"
                  :name="selected.entity.name"
                  :tenant-id="currentTenantId"
                  :user-name="userName"
                  :entity="selected"
                  @delete="entityDeleted"
                  @change:selected="changeSelected($event)"
                />
                <info-card-site
                  v-if="selected.entity.entity_type === 'SITE'"
                  :id="selected.entity.id"
                  :tenant-id="currentTenantId"
                  :user-name="userName"
                  @delete="entityDeleted"
                  @refreshPage="fetchEntities"
                />
              </v-card-text>
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import InfoCardSite from '@/components/sites/SiteCardInfo.vue'
import InfoCardGroup from '@/components/site-groups/SiteGroupCardInfo.vue'
import InfoCardTenant from '@/components/tenants/TenantCardInfo.vue'
import AddEntityDialog from '@/components/sites/SiteDialogAdd.vue'
export default {
  components: {
    InfoCardSite,
    InfoCardGroup,
    InfoCardTenant,
    AddEntityDialog
  },
  data: () => ({
    caseSensitive: false,
    search: null,
    active: [],
    avatar: null,
    open: [],
    loading: false
  }),
  computed: {
    ...mapGetters(['entities', 'currentTenantId', 'userName']),
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
    selected () {
      if (!this.active.length) return undefined
      return this.active[0]
    }
  },
  watch: {
    currentTenantId: {
      immediate: false,
      async handler () {
        if (this.currentTenantId === 'system') {
          this.$router.push('/tenants')
        } else {
          this.active = []
          await this.fetchEntities()
          this.openLastActiveEntity()
        }
      }
    },
    active: {
      handler (value) {
        if (!value || !value.length) {
          return
        }
        sessionStorage.setItem(`${this.currentTenantId}-active`, JSON.stringify(value[0]))
      }
    }
  },
  async created () {
    if (this.currentTenantId === 'system') { this.$router.push('/tenants') } else {
      this.loading = true
      await this.fetchEntities()
      await this.$store.dispatch('fetchUsers')
      this.openLastActiveEntity()
      this.loading = false
    }
  },
  methods: {
    onEntitiesAdd (parentEntity, newEntities) {
      const entities = []
      for (const newEntity of newEntities) {
        entities.push({
          entity: { ...newEntity },
          child_entities: [],
          siteNumber: 0,
          nameAndNumber: newEntity.name
        })
      }
      this.updateParentsOnNewEntities(parentEntity, ...entities)
      this.active = [entities[0]]
    },
    onEntityAdd (parentEntity, newEntity) {
      newEntity.child_entities = []
      newEntity.nameAndNumber = newEntity.entity.name
      newEntity.siteNumber = 0
      this.updateParentsOnNewEntities(parentEntity, newEntity)
      this.active = [newEntity]
    },
    updateParentsOnNewEntities (parentEntity, ...entities) {
      parentEntity.child_entities.unshift(...entities)
      this.open.push(parentEntity)
      if (entities[0].entity.entity_type !== 'SITE') {
        return
      }

      do {
        parentEntity.nameAndNumber = `${parentEntity.entity.name} (${parentEntity.siteNumber + entities.length})`
        parentEntity = this.getParentEntityFromTree(this.entities[0], parentEntity.entity.parent_entity_id)
      } while (parentEntity)
    },
    openLastActiveEntity () {
      const active = JSON.parse(sessionStorage.getItem(`${this.currentTenantId}-active`)) || this.entities[0]
      this.active = [active]
    },
    async changeSelected (event) {
      const entity = JSON.parse(event)
      this.active = [entity]
      await this.openTreeUpstream(entity)
    },
    async openTreeUpstream (entity) {
      this.open = []
      var id = entity.entity.parent_entity_id
      while (id) {
        entity = this.getParentEntityFromTree(this.entities[0], id)
        this.open.push(entity)
        id = entity.entity.parent_entity_id
      }
    },
    getParentEntityFromTree (entity, id) {
      if (entity.entity.id === id) {
        return entity
      }
      for (const el of entity.child_entities) {
        const parent = this.getParentEntityFromTree(el, id)
        if (parent) {
          return parent
        }
      }
      return null
    },
    async fetchEntities () {
      this.loading = true
      await this.$store.dispatch('fetchEntities')
      this.loading = false
    },
    entityDeleted () {
      this.active = []
    }
  }
}
</script>
