<template>
  <v-card
    v-if="site"
    max-width="1200"
    flat
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col cols="12">
          <cover-photo
            v-if="coverPhoto"
            :prefix="id"
            :photo-key="coverPhoto"
            @set="setCoverPhoto"
          />
          <v-card-title class="pb-0">
            {{ title }}
            <v-spacer />
            <v-card-actions v-if="canEditEntity">
              <cover-photo-button
                v-if="!coverPhoto"
                :prefix="id"
                @set="setCoverPhoto"
              />
              <SiteButtonDelete
                v-if="canDeleteEntity"
                class="ml-3 mr-3"
                :entity-id="id"
                :tenant-id="tenantId"
                :name="title"
                @delete="$emit('delete')"
              />
              <v-spacer />
              <v-btn
                color="success"
                @click="saveChanges"
              >
                <v-icon left>
                  mdi-check-circle-outline
                </v-icon>
                {{ $t('Save Changes') }}
              </v-btn>
            </v-card-actions>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="name"
                    prepend-icon="mdi-badge-account-horizontal-outline"
                    :readonly="!canEditEntity"
                    :label="$t('Name')"
                    :rules="nameRules"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="description"
                    prepend-icon="mdi-comment-text-outline"
                    :readonly="!canEditEntity"
                    :label="$t('Description')"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="erpCode"
                    prepend-icon="mdi-qrcode"
                    :readonly="!canEditEntity"
                    :label="$t('ERP Code')"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <search-address
                    :address="address"
                    :readonly="!canEditEntity"
                    @select="address = $event"
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="contactName"
                    prepend-icon="mdi-account-circle-outline"
                    :readonly="!canEditEntity"
                    :label="$t('Contact Name')"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <inputTel
                    v-model="contactPhone"
                    prepend-icon="mdi-phone-outline"
                    :readonly="!canEditEntity"
                    :label="$t('Contact Phone')"
                    outlined
                    dense
                  />
                </div>
              </v-col>
              <v-col>
                <div class="my-2 subtitle-1">
                  <language-input
                    v-model="locale"
                    :readonly="!canEditEntity"
                    :label="$t('Language')"
                    prepend-icon="mdi-translate"
                    outlined
                    dense
                  />
                </div>
                <div class="subtitle-1">
                  <v-select
                    v-model="currency"
                    :menu-props="{offsetY:true}"
                    :items="currencies"
                    :readonly="!canEditEntity"
                    :label="$t('Currency')"
                    prepend-icon="mdi-currency-usd-circle-outline"
                    item-text="displayName"
                    return-object
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-select
                    v-model="height"
                    :menu-props="{offsetY:true}"
                    :items="heights"
                    :readonly="!canEditEntity"
                    :label="$t('Height')"
                    prepend-icon=" mdi-ruler"
                    item-text="symbol"
                    return-object
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-select
                    v-model="temperature"
                    :menu-props="{offsetY:true}"
                    :items="temperatures"
                    :readonly="!canEditEntity"
                    :label="$t('Temperature')"
                    prepend-icon="mdi-thermometer"
                    item-text="symbol"
                    return-object
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-select
                    v-model="volume"
                    :menu-props="{offsetY:true}"
                    :items="volumes"
                    :readonly="!canEditEntity"
                    :label="$t('Volume')"
                    prepend-icon="mdi-oil-temperature"
                    item-text="symbol"
                    return-object
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <treeselect
                    :selected-items="selectedEntities"
                    :tree-items="allEntities"
                    :label="$t('Tree')"
                    :selectable="false"
                    :rules="primaryEntityRules"
                    :activatable="true"
                    :disabled="!canEditEntity"
                    :dense="true"
                    prepend-icon="mdi-sitemap"
                    @select="entitySelected"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="12">
          <v-card-title class="pb-2 text-subtitle-1 font-weight-bold">
            {{ $t('MiSite Settings') }}
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col>
                <div class="mt-1 subtitle-1">
                  <v-select
                    v-model="dataSource"
                    :menu-props="{offsetY:true}"
                    :items="dataSources"
                    :readonly="!canEditEntity"
                    :label="$t('Data Source')"
                    prepend-icon="mdi-cog-outline"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="referenceid"
                    prepend-icon="mdi-identifier"
                    :readonly="!canEditEntity"
                    :label="$t('Reference Id')"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="alarmDuration"
                    prepend-icon="mdi-alarm"
                    :readonly="!canEditEntity"
                    :label="$t('Minimum Alarm Duration')"
                    outlined
                    dense
                    :rules="alarmDurationRules"
                  />
                </div>
              </v-col>
              <v-col>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="misiteUsername"
                    prepend-icon="mdi-account-circle-outline"
                    :readonly="!canEditEntity"
                    :label="$t('Username')"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="password"
                    prepend-icon="mdi-form-textbox-password"
                    :readonly="!canEditEntity"
                    :label="$t('Password')"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    dense
                    @click:append="showPassword = !showPassword"
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="id"
                    prepend-icon="mdi-identifier"
                    readonly
                    filled
                    :label="$t('Site Id')"
                    outlined
                    dense
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
        <!-- Commented out due to vulnerability
          <album-list
            :prefix="id"
            @set:cover-photo="setCoverPhoto"
          />
        -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card-text class="pa-0 ma-0">
            <UserDirectory
              v-if="canReadUsers"
              :can-edit-entity="canEditEntity"
              :entity-id="id"
              :tenant-id="tenantId"
            />
          </v-card-text>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// import AlbumList from '@/components/common/photos/album/List.vue'
import UserDirectory from '@/components/users/UserDirectory.vue'
import Treeselect from '@/components/common/Treeselect.vue'
import SiteButtonDelete from '@/components/sites/SiteButtonDelete.vue'
import InputTel from '@/components/common/InputTel.vue'
import LanguageInput from '@/components/common/LanguageInput.vue'
import SearchAddress from '@/components/common/SearchAddress.vue'
import CoverPhoto from '@/components/common/photos/cover/Item.vue'
import CoverPhotoButton from '@/components/common/photos/cover/SetButton.vue'

export default {
  name: 'SiteCardInfo',
  components: {
    UserDirectory,
    SiteButtonDelete,
    InputTel,
    LanguageInput,
    SearchAddress,
    Treeselect,
    // AlbumList,
    CoverPhoto,
    CoverPhotoButton
  },
  props: {
    id: {
      type: String,
      required: true
    },
    tenantId: {
      type: String,
      required: true
    },
    userName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      valid: false,
      dataSources: ['Ligo', 'PPX'],
      title: '',
      name: '',
      description: '',
      password: '',
      showPassword: false,
      misiteUsername: '',
      alarmDuration: 0,
      alarmDurationRules: [
        v => (v >= 0 && v <= 15) || 'Duration must be between 0-15 minutes',
        v => !!v || 'Please fill in this field'
      ],
      erpCode: '',
      referenceid: '',
      parentEntity: {},
      height: {},
      volume: {},
      temperature: {},
      currency: {},
      locale: {},
      dataSource: '',
      address: '',
      contactName: '',
      contactPhone: '',
      coverPhoto: '',
      dialog: false,
      site: null,
      canEditEntity: false,
      canDeleteEntity: false,
      canReadUsers: false,
      nameRules: [v => !!v || 'Please fill in this field'],
      newTree: [],
      parentEntityChanged: false,
      primaryEntity: {},
      selectedEntities: [],
      primaryEntityRules: [v => !!v || `Please select a ${this.$t('Tree')}`]
    }
  },
  computed: {
    ...mapGetters(['currencies', 'entities', 'volumes', 'heights', 'temperatures', 'locales']),
    allEntities () {
      const entities = this.$store.getters.entities
      let filterEntities = JSON.parse(JSON.stringify(entities))
      filterEntities = this.removeSiteEntities(filterEntities.slice())
      return filterEntities
    },
    images () {
      return [
        { src: 'https://images.globes.co.il/images/NewGlobes/big_image_800/2018/paz800x392.20181009T132348.jpg' },
        { src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRXuz3q6d3O4xfIL9FCGSt9oHmNuW82Hr9B0A&usqp=CAU' },
        { src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQWCOQi8CPuaBg7MJgUfbAqrn1h84N6wu4NPQ&usqp=CAU' }
      ]
    }
  },
  async created () {
    this.loading = true
    await this.getEntity()
    await this.setPermissions()
    this.loading = false
  },
  methods: {
    entitySelected (value) {
      if (!value) return
      this.primaryEntity = value[0]
      this.parentEntityChanged = true
    },
    setCoverPhoto (event) {
      this.coverPhoto = event
      this.saveChanges(false)
    },
    removeSiteEntities (ent) {
      return ent.filter(entity => {
        if (entity.entity.entity_type === 'SITE') { return false }
        if (entity.child_entities.length > 0) {
          entity.child_entities = this.removeSiteEntities(entity.child_entities)
        }
        return true
      })
    },
    async setPermissions () {
      this.canEditEntity = await this.enforce(this.id, 'entity-edit', this.userName, this.tenantId)
      this.canDeleteEntity = await this.enforce(this.id, 'entity-delete', this.userName, this.tenantId)
      this.canReadUsers = await this.enforce(this.id, 'users-read', this.userName, this.tenantId)
    },
    async enforce (entity, action, userName, tenantId) {
      this.loading = true
      try {
        const enforce = await this.$store.dispatch('enforce', {
          userName,
          tenantId,
          entity,
          action
        })
        return enforce
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
        return false
      } finally {
        this.loading = false
      }
    },
    async getEntityWithId (id, tenantId) {
      if (!id || !tenantId) return
      const data = await this.$store.dispatch('getEntity', {
        tenantId: tenantId,
        entityId: id
      })
      return data
    },
    async getEntity () {
      const data = await this.$store.dispatch('getEntity', {
        tenantId: this.tenantId,
        entityId: this.id
      })
      const selEnt = await this.getEntityWithId(data.parent_entity_id, data.tenant_id)
      this.selectedEntities = []

      this.site = data
      this.title = data.name
      this.name = data.name
      this.description = data.description
      this.password = data.password
      this.misiteUsername = data.username
      this.alarmDuration = data.alarmDuration || 0
      this.erpCode = data.erpCode
      this.referenceid = data.referenceid
      this.dataSource = data.siteDataSourceId
      this.contactName = data.contactName
      this.contactPhone = data.contactPhone
      this.address = data.address
      this.primaryEntity = selEnt
      this.selectedEntities.push(this.primaryEntity)
      this.locale = this.locales.find(el => el.value === data.locale)
      this.currency = this.currencies.find(el => el.code === data.currencyCode)
      this.volume = this.volumes.find(el => el.code === data.volumeCode)
      this.height = this.heights.find(el => el.code === data.heightCode)
      this.temperature = this.temperatures.find(el => el.code === data.temperatureCode)
      this.coverPhoto = data.coverPhoto
    },
    saveChanges (validate = true) {
      if (validate && !this.$refs.form.validate()) {
        return null
      }
      const primaryEntity = this.primaryEntity.entity || this.primaryEntity

      this.$store
        .dispatch('editEntity', {
          tenantId: this.tenantId,
          entityId: this.id,
          name: this.name,
          description: this.description,
          attributes: [
            { name: 'parentEntityId', value: primaryEntity.id ?? null },
            { name: 'locale', value: this.locale ? this.locale.value : null },
            { name: 'referenceid', value: this.referenceid },
            { name: 'contactName', value: this.contactName },
            { name: 'contactPhone', value: this.contactPhone },
            { name: 'address', value: this.address },
            { name: 'siteDataSourceId', value: this.dataSource },
            { name: 'username', value: this.misiteUsername },
            { name: 'alarmDuration', value: this.alarmDuration },
            { name: 'erpCode', value: this.erpCode },
            { name: 'password', value: this.password },
            { name: 'currencyCode', value: this.currency ? this.currency.code : null },
            { name: 'volumeCode', value: this.volume ? this.volume.code : null },
            { name: 'heightCode', value: this.height ? this.height.code : null },
            { name: 'temperatureCode', value: this.temperature ? this.temperature.code : null },
            { name: 'coverPhoto', value: this.coverPhoto }
          ]
        })
        .then(async (data) => {
          this.$store.commit('showMessage', { message: `${this.title} updated successfully`, color: 'success' })

          this.getEntity()
          if (this.name !== this.title) {
            this.$store.dispatch('fetchEntities')
          }
          if (this.parentEntityChanged) {
            this.$emit('refreshPage')
            this.parentEntityChanged = false
          }
        }).catch((error) => {
          this.$store.commit('showMessage', { message: error, color: 'error' })
        })
    }
  }
}
</script>
