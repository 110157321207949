<template>
  <v-card
    v-if="group"
    max-width="1200"
    flat
  >
    <v-row>
      <v-col>
        <cover-photo
          v-if="coverPhoto"
          :prefix="id"
          :photo-key="coverPhoto"
          @set="setCoverPhoto"
        />
        <v-card-title class="pb-0">
          {{ title }}
          <v-spacer />
          <v-card-actions v-if="canEditEntity">
            <v-spacer />
            <cover-photo-button
              v-if="!coverPhoto"
              :prefix="id"
              @set="setCoverPhoto"
            />
            <SiteButtonDelete
              v-if="canDeleteEntity"
              class="ml-3 mr-3"
              :entity-id="id"
              :tenant-id="tenantId"
              :name="title"
              @delete="$emit('delete')"
            />
            <v-btn
              color="success"
              @click="saveChanges"
            >
              <v-icon left>
                mdi-check-circle-outline
              </v-icon>
              {{ $t('Save Changes') }}
            </v-btn>
          </v-card-actions>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col>
              <div class="my-2 subtitle-1">
                <v-text-field
                  v-model="name"
                  prepend-icon="mdi-badge-account-horizontal-outline"
                  :readonly="!canEditEntity"
                  :label="$t('Name')"
                  outlined
                  dense
                />
              </div>
              <div class="my-2 subtitle-1">
                <v-text-field
                  v-model="description"
                  prepend-icon="mdi-comment-text-outline"
                  :readonly="!canEditEntity"
                  :label="$t('Description')"
                  outlined
                  dense
                />
              </div>
              <div class="my-2 subtitle-1">
                <v-text-field
                  v-model="erpCode"
                  prepend-icon="mdi-qrcode"
                  :readonly="!canEditEntity"
                  :label="$t('ERP Code')"
                  outlined
                  dense
                />
              </div>
              <div class="my-2 subtitle-1">
                <search-address
                  :address="address"
                  :readonly="!canEditEntity"
                  @select="address = $event"
                />
              </div>
              <div class="my-2 subtitle-1">
                <v-text-field
                  v-model="contactName"
                  prepend-icon="mdi-account-circle-outline"
                  :readonly="!canEditEntity"
                  :label="$t('Contact Name')"
                  outlined
                  dense
                />
              </div>
              <div class="my-2 subtitle-1">
                <inputTel
                  v-model="contactPhone"
                  prepend-icon="mdi-phone-outline"
                  :readonly="!canEditEntity"
                  :label="$t('Contact Phone')"
                  outlined
                  dense
                />
              </div>
            </v-col>
            <v-col>
              <div class="my-2 subtitle-1">
                <language-input
                  v-model="locale"
                  :readonly="!canEditEntity"
                  :label="$t('Language')"
                  prepend-icon="mdi-translate"
                  outlined
                  dense
                />
              </div>
              <div class="subtitle-1">
                <v-select
                  v-model="currency"
                  :menu-props="{offsetY:true}"
                  :items="currencies"
                  :readonly="!canEditEntity"
                  :label="$t('Currency')"
                  prepend-icon="mdi-currency-usd-circle-outline"
                  item-text="displayName"
                  return-object
                  outlined
                  dense
                />
              </div>
              <div class="my-2 subtitle-1">
                <v-select
                  v-model="height"
                  :menu-props="{offsetY:true}"
                  :items="heights"
                  :readonly="!canEditEntity"
                  :label="$t('Height')"
                  prepend-icon=" mdi-ruler"
                  item-text="symbol"
                  return-object
                  outlined
                  dense
                />
              </div>
              <div class="my-2 subtitle-1">
                <v-select
                  v-model="temperature"
                  :menu-props="{offsetY:true}"
                  :items="temperatures"
                  :readonly="!canEditEntity"
                  :label="$t('Temperature')"
                  prepend-icon="mdi-thermometer"
                  item-text="symbol"
                  return-object
                  outlined
                  dense
                />
              </div>
              <div class="my-2 subtitle-1">
                <v-select
                  v-model="volume"
                  :menu-props="{offsetY:true}"
                  :items="volumes"
                  :readonly="!canEditEntity"
                  :label="$t('Volume')"
                  prepend-icon="mdi-oil-temperature"
                  item-text="symbol"
                  return-object
                  outlined
                  dense
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-row v-if="group.address">
          <v-col>
            <entity-children-map
              :parent="group"
              :children="sites"
              @click:child="emit($event)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
      <!-- Commented out due to a security vulnerability
        <album-list
            :prefix="id"
            @set:cover-photo="setCoverPhoto"
          />
      -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card-text class="pa-0 ma-0">
          <UserDirectory
            v-if="canReadUsers"
            :can-edit-entity="canEditEntity"
            :entity-id="id"
            :tenant-id="tenantId"
          />
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// import AlbumList from '@/components/common/photos/album/List.vue'
import UserDirectory from '@/components/users/UserDirectory.vue'
import SiteButtonDelete from '@/components/sites/SiteButtonDelete.vue'
import InputTel from '@/components/common/InputTel.vue'
import LanguageInput from '@/components/common/LanguageInput.vue'
import SearchAddress from '@/components/common/SearchAddress.vue'
import EntityChildrenMap from '@/components/common/EntityChildrenMap.vue'
import CoverPhoto from '@/components/common/photos/cover/Item.vue'
import CoverPhotoButton from '@/components/common/photos/cover/SetButton.vue'

export default {
  name: 'SiteGroupCardInfo',
  components: {
    UserDirectory,
    SiteButtonDelete,
    InputTel,
    SearchAddress,
    LanguageInput,
    EntityChildrenMap,
    // AlbumList,
    CoverPhoto,
    CoverPhotoButton
  },
  props: {
    id: {
      type: String,
      required: true
    },
    tenantId: {
      type: String,
      required: true
    },
    userName: {
      type: String,
      required: true
    },
    entity: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      title: '',
      name: '',
      description: '',
      contactName: '',
      contactPhone: '',
      erpCode: '',
      address: '',
      coverPhoto: '',
      height: {},
      volume: {},
      temperature: {},
      currency: {},
      locale: {},
      dialog: false,
      group: null,
      canEditEntity: false,
      canDeleteEntity: false,
      canReadUsers: false,
      sites: []
    }
  },
  computed: {
    ...mapGetters(['currencies', 'volumes', 'heights', 'temperatures', 'locales']),
    images () {
      return [
        { src: 'https://images.globes.co.il/images/NewGlobes/big_image_800/2018/paz800x392.20181009T132348.jpg' },
        { src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRXuz3q6d3O4xfIL9FCGSt9oHmNuW82Hr9B0A&usqp=CAU' },
        { src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQWCOQi8CPuaBg7MJgUfbAqrn1h84N6wu4NPQ&usqp=CAU' }
      ]
    }
  },
  async created () {
    this.loading = true
    await this.getEntity()
    await this.setPermissions()
    if (this.entity) {
      this.sites = this.getSites(this.entity).flat(Infinity)
    }
    this.loading = false
  },
  methods: {
    setCoverPhoto (event) {
      this.coverPhoto = event
      this.saveChanges()
    },
    getSites (entity) {
      if (entity.entity.entity_type === 'SITE') {
        return entity
      }
      var sites = []
      for (const child of entity.child_entities) {
        sites.push(this.getSites(child))
      }
      return sites
    },
    emit (event) {
      this.$emit('change:selected', event)
    },
    async setPermissions () {
      this.canEditEntity = await this.enforce(this.id, 'entity-edit', this.userName, this.tenantId)
      this.canDeleteEntity = await this.enforce(this.id, 'entity-delete', this.userName, this.tenantId)
      this.canReadUsers = await this.enforce(this.id, 'users-read', this.userName, this.tenantId)
    },
    async enforce (entity, action, userName, tenantId) {
      this.loading = true
      try {
        const enforce = await this.$store.dispatch('enforce', {
          userName,
          tenantId,
          entity,
          action
        })
        return enforce
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
        return false
      } finally {
        this.loading = false
      }
    },
    async getEntity () {
      const data = await this.$store.dispatch('getEntity', {
        tenantId: this.tenantId,
        entityId: this.id
      })
      this.group = data
      this.title = data.name
      this.name = data.name
      this.description = data.description
      this.contactName = data.contactName
      this.contactPhone = data.contactPhone
      this.erpCode = data.erpCode
      this.address = data.address
      this.locale = this.locales.find(el => el.value === data.locale)
      this.currency = this.currencies.find(el => el.code === data.currencyCode)
      this.volume = this.volumes.find(el => el.code === data.volumeCode)
      this.height = this.heights.find(el => el.code === data.heightCode)
      this.temperature = this.temperatures.find(el => el.code === data.temperatureCode)
      this.coverPhoto = data.coverPhoto
    },
    saveChanges () {
      this.$store
        .dispatch('editEntity', {
          tenantId: this.tenantId,
          entityId: this.id,
          name: this.name,
          description: this.description,
          attributes: [
            { name: 'contactName', value: this.contactName },
            { name: 'erpCode', value: this.erpCode },
            { name: 'address', value: this.address },
            { name: 'contactPhone', value: this.contactPhone },
            { name: 'locale', value: this.locale ? this.locale.value : null },
            { name: 'currencyCode', value: this.currency ? this.currency.code : null },
            { name: 'volumeCode', value: this.volume ? this.volume.code : null },
            { name: 'heightCode', value: this.height ? this.height.code : null },
            { name: 'temperatureCode', value: this.temperature ? this.temperature.code : null },
            { name: 'coverPhoto', value: this.coverPhoto }
          ]
        })
        .then((data) => {
          this.$store.commit('showMessage', { message: `${this.title} updated successfully`, color: 'success' })
          this.getEntity()
          if (this.name !== this.title) {
            this.$store.dispatch('fetchEntities')
          }
        })
    }
  }
}
</script>
